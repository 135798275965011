// import { REHYDRATE } from 'redux-persist';
import { SUPERADMIN, ADMIN, EDITOR } from '../constants/userTypes';
import { LOGIN, LOGOUT } from './constants/actionTypes';

const initState = {
    logindata: {},
    graph_data: {},
    customer_data: [],
    assets: [],
    tag: undefined,
    user: undefined,
    tags: [],
    users: [],
    edit_data: {},
    login_type: undefined,
    permission_type: undefined,
    interval: {},
    geocode_data: [],
    timezone_data: [],
    session_management: {},
    last_session_time: undefined,
    isLoggedIn: false
};

function reducer(state = initState, action) {
    switch (action.type) {

        case LOGIN:
            const permission_type = action.res.idToken.payload['custom:permissionType']
            return {
                ...state,
                logindata: action.res,
                token: action.res.idToken.jwtToken,
                // client_id: action.res.accessToken.payload.client_id,
                userid: action.res.accessToken.payload.sub,
                email: action.res.idToken.payload.email,
                permission_type,
                isSuperAdmin: permission_type === SUPERADMIN,
                isAdmin: [ADMIN, SUPERADMIN].includes(permission_type),
                canEdit: [EDITOR, ADMIN, SUPERADMIN].includes(permission_type),
                isLoggedIn: true
            };

        case LOGOUT:

            if (state.interval) {
                clearInterval(state.interval);
            }

            return {
                ...state,
                logindata: {},
                graph_data: {},
                customer_data: [],
                assets: [],
                tag: true,
                user: false,
                tags: [],
                users: [],
                edit_data: {},
                geocode_data: [],
                login_type: undefined,
                permission_type: undefined,
                interval: {},
                session_management: {},
                session_flag: action.res ? true : undefined,
                last_session_time: undefined,
                isLoggedIn: false
            };
        case 'Graph':
            return {
                ...state,
                graph_data: action.res
            };
        case 'Customer':
            return {
                ...state,
                customer_data: action.res
            };
        case 'AssetList':
            return {
                ...state,
                assets: action.res
            };
        case 'AddUser':
            return {
                ...state,
                user: action.res
            };
        case 'AddTag':
            return {
                ...state,
                tag: action.res
            };
        case 'TagList':
            return {
                ...state,
                tags: action.res
            };
        case 'EditDataFn':
            return {
                ...state,
                edit_data: action.res
            };
        case 'UserList':
            return {
                ...state,
                users: action.res
            };
        // case 'LoginType':
        //     return {
        //         ...state,
        //         login_type: action.res.Value,
        //         permission_type: action.res.Value
        //     };
        // case 'clearInterval':
        //     return {
        //         ...state,
        //         interval: action.res
        //     };
        case 'Geocode':
            return {
                ...state,
                geocode_data: action.res
            };
        case 'Timezone':
            return {
                ...state,
                timezone_data: action.res
            };
        // case 'SessionManagement':
        //     return {
        //         ...state,
        //         session_management: action.res
        //     };
        // case 'SessionManagementTime':
        //     return {
        //         ...state,
        //         last_session_time: action.res
        //     };

        // case REHYDRATE:
        //     return {
        //         ...state,
        //         logindata: action.payload &&
        //             action.payload.logindata ? action.payload.logindata : {},
        //         last_session_time: action.payload &&
        //             action.payload.last_session_time ? action.payload.last_session_time : undefined,
        //         session_management: action.payload &&
        //             action.payload.session_management ? action.payload.session_management : {},
        //         geocode_data: action.payload &&
        //             action.payload.geocode_data ? action.payload.geocode_data : [],
        //         timezone_data: action.payload &&
        //             action.payload.timezone_data ? action.payload.timezone_data : [],
        //         graph_data: action.payload &&
        //             action.payload.graph_data ? action.payload.graph_data : {},
        //         customer_data: action.payload &&
        //             action.payload.customer_data ? action.payload.customer_data : [],
        //         assets: action.payload &&
        //             action.payload.asset_list ? action.payload.asset_list : [],
        //         tag: true,
        //         user: true,
        //         tags: action.payload &&
        //             action.payload.tags ? action.payload.tags : [],
        //         user_list: action.payload &&
        //             action.payload.user_list ? action.payload.user_list : [],
        //         edit_data: action.payload &&
        //             action.payload.edit_data ? action.payload.edit_data : {},
        //         login_type: action.payload &&
        //             action.payload.login_type ? action.payload.login_type : undefined,
        //         permission_type: action.payload &&
        //             action.payload.permission_type ? action.payload.permission_type : undefined,
        //         interval: action.payload && action.payload.interval
        //     };
        default:
            return state;
    }
}

export default reducer;
