import React from 'react';
import classNames from 'classnames';

import Row from './Row';
import Cell from './Cell';
import MediaQuery from './MediaQuery';
import { spaced as spacedStyle } from './Spaced.styl';

import '../base';
import style from './Layout.styl';

export function Page({ children }) {
  return (
    <div className={style.page}>
      {children}
    </div>
  );
}

export function Header({ children }) {
  return (
    <div className={style.header}>
      {children}
    </div>
  );
}

export function ContentWrapper({ children }) {
  return (
    <div className={style.contentWrapper}>
      {children}
    </div>
  );
}

export function Content({ menu, fullWidth = false, children }) {
  const sidebar = menu && React.createElement(menu);
  const classes = classNames({
    [style.content]: true,
    [style.fullWidth]: fullWidth || sidebar
  });

  return (
    <div className={classes}>
      <Row>
        {sidebar && !fullWidth && (
          <MediaQuery medium>
            <Cell style={{ maxWidth: 309,'background-color': '#003594' }}>
              {sidebar}
            </Cell>
          </MediaQuery>
        )}
        <Cell className={spacedStyle} 
        >
        {/* style={{ overflowY: 'auto', overflowX: 'hidden'}} */}
          {children}
        </Cell>
      </Row>
    </div>
  );
}

export function Footer({ children, fullWidth }) {
  const classes = classNames({
    [style.footer]: true,
    [style.fullWidth]: fullWidth
  });
  return (
    <div className={classes}>
      {children}
    </div>
  );
}

export function LeftCell({ children, xs = '12', sm = '6' }) {
  return (
    <Cell xs={xs} sm={sm}>
      <div className={`${style.left} ${spacedStyle}`}>
        {children}
      </div>
    </Cell>
  );
}

export function RightCell({ children, xs = '12', sm = '6' }) {
  return (
    <Cell xs={xs} sm={sm}>
      <div className={`${style.right} ${spacedStyle}`}>
        {children}
      </div>
    </Cell>
  );
}

export function Backdrop() {
  return (
    <div className={style.backdrop} />
  );
}
