import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CommonFooter from '../../common/footer'
import { Content, Page } from '../../components/atoms/Layout'
import { connect } from 'react-redux'
import routes from '../../routes';
import sideMenuItems from '../../sidemenu-items';
import SideMenu from '../../components/atoms/SideMenu';
import Button from '../../components/atoms/Button';

import './index.css';

const buttonStyle = { width: '100%', textAlign: 'start' };

class DefaultLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            // redirect: false,
            path: null
        };
    }

    componentDidMount() {
        const path = window.location.hash.split('#')[1];
        this.setState({ path });
    }

    redirectFn = (history, path) => {
        history.push(path);
        this.setState({ path });
    }

    callLoaderFn = loading => {
        this.setState({ loading });
    };

    render() {
        // if (this.state.redirect && window.location.hash !== `#${this.state.path}`) {
        //     console.log('redirecting to', this.state.path)
        //     return (<Redirect to={this.state.path} />);
        // }

        return (
            <Page>
                <SideMenu.Container>
                    <SideMenu.Group>
                        {sideMenuItems.map((item, idx) => {
                            return (item.isPublic || this.props.isAdmin) && <SideMenu.Item key={idx}>
                                <Route render={({ history }) => (
                                    <Button primary
                                        className={this.state.path === item.path ? 'active' : ''}
                                        // onClick={() => history.push(item.path)}
                                        onClick={() => this.redirectFn(history, item.path)}
                                        icon={item.icon}
                                        style={buttonStyle}>
                                        {item.text}
                                    </Button>
                                )} />
                            </SideMenu.Item>
                        })}
                    </SideMenu.Group>
                </SideMenu.Container>

                <Content>
                    <Switch>
                        {routes.filter(r => r.component && r.type.includes(this.props.permission_type)).map((route, idx) => {
                            return (<Route key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component
                                        {...props}
                                        loaderfn={this.callLoaderFn}
                                        loading={this.state.loading}
                                    />)}
                            />);

                        })}

                        <Route exact path='/'>
                            <Redirect to='/dashboard' />
                        </Route>
                    </Switch>
                </Content>

                <CommonFooter />
            </Page>
        );
    }
}

const mapStateToProps = state => ({
    permission_type: state.permission_type,
    isAdmin: state.isAdmin
});

export default connect(mapStateToProps)(DefaultLayout);
