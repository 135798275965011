// import React from 'react';
import Loadable from 'react-loadable';
import { SUPERADMIN, ADMIN, EDITOR, VIEWER } from './constants/userTypes';
import ThreeDots from './components/loaders/ThreeDots';

const loading = ThreeDots;

// Components
const Dashboard = Loadable({ loader: () => import('./Pages/Dashboard/dashboard'), loading });
const File = Loadable({ loader: () => import('./Pages/Files/file'), loading });
const Tags = Loadable({ loader: () => import('./Pages/Tags/tags'), loading });
const AddTags = Loadable({ loader: () => import('./Pages/Tags/addtag'), loading });
const User = Loadable({ loader: () => import('./Pages/User/user'), loading });
const AddUser = Loadable({ loader: () => import('./Pages/User/adduser'), loading });
const Assets = Loadable({ loader: () => import('./Pages/Assets/assets'), loading });
const AddEditAsset = Loadable({ loader: () => import('./Pages/Assets/editAsset'), loading });
const SingleOperatorView = Loadable({ loader: () => import('./Pages/SingleOperatorView/singleoperatorView'), loading });

// User types
const allUsers = [SUPERADMIN, ADMIN, EDITOR, VIEWER];
const onlyAdmins = [SUPERADMIN, ADMIN];

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard, type: allUsers },
    { path: '/files', exact: true, name: 'File', component: File, type: allUsers },
    { path: '/tags', exact: true, name: 'Tags', component: Tags, type: allUsers },
    { path: '/tags/add', name: 'Tags', component: AddTags, type: allUsers },
    { path: '/tags/edit', name: 'Tags', component: AddTags, type: allUsers },
    { path: '/users', exact: true, name: 'User', component: User, type: onlyAdmins },
    { path: '/users/add', name: 'User', component: AddUser, type: onlyAdmins },
    { path: '/users/edit', name: 'User', component: AddUser, type: onlyAdmins },
    { path: '/assets', exact: true, name: 'Assets', component: Assets, type: allUsers },
    { path: '/assets/edit', exact: true, name: 'AddEditAsset', component: AddEditAsset, type: allUsers },
    { path: '/dashboard/asset', name: 'Single Operator View', component: SingleOperatorView, type: allUsers }
];

export default routes;
