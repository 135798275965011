import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../base';

export default function Row({ children, style, className, ...props }) {

  const classes = classNames({
    row: true,
    [className]: className,
    'start-xs': props.xsStart || props.start,
    'start-sm': props.smStart,
    'start-md': props.mdStart,
    'start-lg': props.lgStart,
    'center-xs': props.xsCenter || props.center,
    'center-sm': props.smCenter,
    'center-md': props.mdCenter,
    'center-lg': props.lgCenter,
    'end-xs': props.xsEnd || props.end,
    'end-sm': props.smEnd,
    'end-md': props.mdEnd,
    'end-lg': props.lgEnd,
    'top-xs': props.xsTop || props.top,
    'top-sm': props.smTop,
    'top-md': props.mdTop,
    'top-lg': props.lgTop,
    'middle-xs': props.xsMiddle || props.middle,
    'middle-sm': props.smMiddle,
    'middle-md': props.mdMiddle,
    'middle-lg': props.lgMiddle,
    'bottom-xs': props.xsBottom || props.bottom,
    'bottom-sm': props.smBottom,
    'bottom-md': props.mdBottom,
    'bottom-lg': props.lgBottom,
    'around-xs': props.xsAround || props.around,
    'around-sm': props.smAround,
    'around-md': props.mdAround,
    'around-lg': props.lgAround,
    'between-xs': props.xsBetween || props.between,
    'between-sm': props.smBetween,
    'between-md': props.mdBetween,
    'between-lg': props.lgBetween,
    reverse: props.reverse
  });

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
}

Row.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  start: PropTypes.bool,
  xsStart: PropTypes.bool,
  smStart: PropTypes.bool,
  mdStart: PropTypes.bool,
  lgStart: PropTypes.bool,
  center: PropTypes.bool,
  xsCenter: PropTypes.bool,
  smCenter: PropTypes.bool,
  mdCenter: PropTypes.bool,
  lgCenter: PropTypes.bool,
  end: PropTypes.bool,
  xsEnd: PropTypes.bool,
  smEnd: PropTypes.bool,
  mdEnd: PropTypes.bool,
  lgEnd: PropTypes.bool,
  top: PropTypes.bool,
  xsTop: PropTypes.bool,
  smTop: PropTypes.bool,
  mdTop: PropTypes.bool,
  lgTop: PropTypes.bool,
  middle: PropTypes.bool,
  xsMiddle: PropTypes.bool,
  smMiddle: PropTypes.bool,
  mdMiddle: PropTypes.bool,
  lgMiddle: PropTypes.bool,
  bottom: PropTypes.bool,
  xsBottom: PropTypes.bool,
  smBottom: PropTypes.bool,
  mdBottom: PropTypes.bool,
  lgBottom: PropTypes.bool,
  around: PropTypes.bool,
  xsAround: PropTypes.bool,
  smAround: PropTypes.bool,
  mdAround: PropTypes.bool,
  lgAround: PropTypes.bool,
  between: PropTypes.bool,
  xsBetween: PropTypes.bool,
  smBetween: PropTypes.bool,
  mdBetween: PropTypes.bool,
  lgBetween: PropTypes.bool,
  reverse: PropTypes.bool
};
