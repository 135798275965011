import React from 'react';
import classNames from 'classnames';

import '../base';
import styles from './PageFooter.styl';
// import Link from './Link';
import { visualProvided } from './VisualProvider';

function PageFooter({ children, t }) {
  const footerClasses = classNames({
    [styles.footer]: true
  });

  return (
    <div className={footerClasses}>
      <div style={{ textAlign: 'left' }}>
        {/* <Link
          external
          target="_blank"
          href={t('Dormakaba', { defaultValue: 'https://www.dormakaba.com/' })}
        >
          {t('Dormakaba', { defaultValue: 'Dormakaba ' })}
        </Link> */}
          <div>{process.env.REACT_APP_NAME} version {process.env.REACT_APP_VERSION}</div>
          <div>Copyright &copy; {(new Date()).getFullYear()} <a href='https://www.dormakaba.com/' target='_blank' rel='noopener noreferrer'>dormakaba</a>. All rights reserved.</div>
        {/* <div style={{ float: 'right' }}>
          {children || t('copyrightLabel', { defaultValue: '© dormakaba 2018' })}
        </div> */}
      </div>
    </div>
  );
}

export default visualProvided(PageFooter);
