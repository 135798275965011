import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from './Icon';
import Button from './Button';
import styles from './IconButton.styl';

import '../base';

export default function IconButton({ small, secondary, children, icon, style, checked, active, className, ...props }) {

  const buttonClasses = classNames({
    [className]: className,
    [styles.btn]: true
  });

  const contentClasses = classNames({
    [styles.content]: true,
    [styles.secondary]: secondary,
    [styles.small]: small,
    [styles.active]: active,
    [styles.checkboxify]: checked !== undefined,
    [styles.checked]: checked
  });

  let h = secondary ? 80 : 80;
  if (small) h = 50;

  return (
    <Button minWidth={false} className={buttonClasses} style={{ ...style, width: h, height: h, padding: 0 }} {...props} >
      <div className={contentClasses}>
        {
          checked === false ? <Icon name="doka-icon-mradioinactive" /> : null
        }
        {
          checked === true ? <Icon name="doka-icon-mradioactive" /> : null
        }
        {
          !small && children && (
            <div className={styles.label}>
              {children}
            </div>
          )
        }
        <Icon name={icon} />
      </div>
    </Button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node
};
