import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../base';
import styles from './Cell.styl';

export default function Cell({ xs, sm, md, lg, xsOff, smOff, mdOff, lgOff, fixed70, children, className, style, ...props }) {

  const classes = classNames({
    [className]: className,
    col: true,
    'col-xs': true,
    [`col-xs-${xs}`]: !!xs,
    [`col-sm-${sm}`]: !!sm,
    [`col-md-${md}`]: !!md,
    [`col-lg-${lg}`]: !!lg,
    [`col-xs-offset-${xsOff}`]: !!xsOff,
    [`col-sm-offset-${smOff}`]: !!smOff,
    [`col-md-offset-${mdOff}`]: !!mdOff,
    [`col-lg-offset-${lgOff}`]: !!lgOff,
    'first-xs': props.xsFirst || props.first,
    'first-sm': props.smFirst,
    'first-md': props.mdFirst,
    'first-lg': props.lgFirst,
    'last-xs': props.xsLast || props.last,
    'last-sm': props.smLast,
    'last-md': props.mdLast,
    'last-lg': props.lgLast,
    [styles.fixed70]: fixed70
  });

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
}

Cell.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  xs: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xsOff: PropTypes.string,
  smOff: PropTypes.string,
  mdOff: PropTypes.string,
  lgOff: PropTypes.string,
  first: PropTypes.bool,
  xsFirst: PropTypes.bool,
  smFirst: PropTypes.bool,
  mdFirst: PropTypes.bool,
  lgFirst: PropTypes.bool,
  last: PropTypes.bool,
  xsLast: PropTypes.bool,
  smLast: PropTypes.bool,
  mdLast: PropTypes.bool,
  lgLast: PropTypes.bool,
  fixed70: PropTypes.bool
};
