import './constants/actionTypes';
import { LOGIN, LOGOUT, ASSETLIST } from './constants/actionTypes';

export function Login(res) { return { type: LOGIN, res }; }
export function Logout() { return { type: LOGOUT }; }
export function Graph(res) { return { type: 'Graph', res }; }
export function Customer(res) { return { type: 'Customer', res }; }
export function AssetList(res) { return { type: ASSETLIST, res }; }
// export function Cognito(res) { return { type: 'Cognito', res }; }
export function AddUser(res) { return { type: 'AddUser', res }; }
export function AddTag(res) { return { type: 'AddTag', res }; }
export function TagList(res) { return { type: 'TagList', res }; }
export function UserList(res) { return { type: 'UserList', res }; }
export function EditDataFn(res) { return { type: 'EditDataFn', res }; }
// export function TabType(res) { return { type: 'TabType', res }; }
// export function LoginType(res) { return { type: 'LoginType', res }; }
// export function clearInterval(res) { return { type: 'clearInterval', res }; }
export function Geocode(res) { return { type: 'Geocode', res }; }
export function Timezone(res) { return { type: 'Timezone', res }; }
// export function SessionManagement(res) { return { type: 'SessionManagement', res }; }
// export function SessionManagementTime(res) { return { type: 'SessionManagementTime', res }; }
