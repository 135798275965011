import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from './Icon';
import ButtonMenu from './Button.Menu';
import ButtonGroup from './Button.Group';
import Spinner from './Spinner';

import '../base';
import styles from './Button.styl';
import menuStyle from './Button.Menu.styl';
import groupStyle from './Button.Group.styl';
import { visualProvided } from './VisualProvider';

function Button({
  t,
  minWidth = true,
  isOffline,
  className,
  children,
  primary,
  icon,
  checked,
  large,
  small,
  style,
  danger,
  pagination,
  dashboard,
  disabled,
  onClick,
  type = 'button',
  spinner,
  left,
  ...rest
}) {
  const classes = classNames({
    [className]: className,
    [styles.btn]: true,
    [styles.minWidth]: minWidth,
    [menuStyle.btn]: true,
    [groupStyle.btn]: true,
    [styles.secondary]: !primary,
    [styles.danger]: danger,
    [styles.pagination]: pagination,
    [styles.dashboard]: dashboard,
    [styles.checkboxify]: checked !== undefined,
    [styles.checked]: checked,
    [styles.large]: large,
    [styles.small]: small
  });

  return (
    <button
      {...rest}
      className={classes}
      style={style}
      disabled={isOffline() && type === 'submit' ? isOffline() : disabled}
      onClick={onClick}
      type={type}
    >
      {
        icon ? (left ? <Icon className={styles.iconn} 
        name={icon} /> :  <Icon className={styles.icon} 
        name={icon} />): null
      }
      { spinner && <Spinner className={styles.spinner} /> }
      {
        spinner ? <div className={styles.childrenWrapper}>{children}</div> : children
      }
      {
        checked === false ? <Icon name="doka-icon-mcheckinactive" /> : null
      }
      {
        checked === true ? <Icon name="doka-icon-mcheckactive" /> : null
      }
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  pagination: PropTypes.bool,
  dashboard: PropTypes.bool,
  icon: PropTypes.string,
  checked: PropTypes.bool,
  spinner: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  left: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string
};

const Extended = visualProvided(Button);
Extended.Menu = ButtonMenu;
Extended.Group = ButtonGroup;
export default Extended;
