import React from 'react';
import PropTypes from 'prop-types';
import MQuery from 'react-responsive';

import '../base';

const breakpoints = {
  small: 568,
  medium: 920,
  large: 1023,
  xlarge: 1200
};

export default function MediaQuery({ smallerThan, small, medium, large, xlarge, children, ...props }) {

  let width = 0;
  if (small) width = breakpoints.small;
  if (medium) width = breakpoints.medium;
  if (large) width = breakpoints.large;
  if (xlarge) width = breakpoints.xlarge;

  const widthProps = {
    // Ensure the maxWidth moves up to but excluding the breakpoint.
    maxWidth: smallerThan ? (width - 1) : undefined,
    minWidth: !smallerThan ? width : undefined
  };

  return (
    <MQuery {...widthProps} {...props}>
      {children}
    </MQuery>
  );
}

MediaQuery.propTypes = {
  children: PropTypes.node,
  smallerThan: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  xlarge: PropTypes.bool
};
