import React, { Component } from 'react';
import { Footer } from '../components/atoms/Layout';
import PageFooter from '../components/atoms/PageFooter';
import { connect } from 'react-redux';

class CommonFooter extends Component {
    render() {
        return (
            <Footer fullWidth={!this.props.email}>
                <PageFooter />
            </Footer>
        );
    }
}

const mapStateToProps = state => {
    return { email: state.email };
};

export default connect(mapStateToProps)(CommonFooter);
