import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../base';

import styles from './Button.Group.styl';

export default function ButtonGroup({ secondary, children }) {

  const classes = classNames({
    [styles.group]: true,
    [styles.secondary]: secondary
  });

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired
};
