import React from 'react';
import PropTypes from 'prop-types';

const SVG_ICONS = {
  spinner: {
    size: [
      0, 0, 20, 20
    ],
    path: (
      <g>
        <path d="M1.357,9.177 C1.457,7.674 2.04,6.121 2.998,4.803 L3.044,4.74 L2.249,3.811 L2.174,3.909 C0.961,5.486 0.265,7.303 0.162,9.165 L0.155,9.27 L1.351,9.27 L1.357,9.177 Z" />
        <path d="M4.094,16.291 L4.023,16.22 L3.104,17.139 L3.181,17.209 C4.753,18.66 6.698,19.573 8.66,19.78 L8.77,19.792 L8.77,18.486 L8.682,18.474 C6.911,18.253 5.281,17.478 4.094,16.291 Z" />
        <path d="M18.528,9.181 L18.538,9.27 L19.845,9.27 L19.834,9.16 C19.605,6.88 18.692,4.777 17.263,3.238 L17.197,3.167 L16.162,4.073 L16.231,4.148 C17.506,5.539 18.322,7.327 18.528,9.181 Z" />
        <path d="M3.105,15.081 C2.146,13.91 1.58,12.4 1.467,10.712 L1.461,10.619 L0.155,10.619 L0.161,10.724 C0.265,12.585 0.96,14.403 2.173,15.979 L2.242,16.07 L3.161,15.151 L3.105,15.081 Z" />
        <path d="M18.417,10.708 C18.194,12.721 17.4,14.46 16.123,15.738 L16.052,15.808 L16.971,16.727 L17.041,16.651 C18.569,15.005 19.507,12.844 19.612,10.723 L19.618,10.619 L18.427,10.619 L18.417,10.708 Z" />
        <path d="M3.181,2.79 L3.104,2.861 L4.023,3.78 L4.094,3.709 C5.282,2.522 6.911,1.747 8.682,1.525 L8.77,1.514 L8.77,0.208 L8.66,0.219 C6.699,0.426 4.753,1.339 3.181,2.79 Z" />
        <path d="M10.224,0.108 L10.119,0.103 L10.119,1.408 L10.213,1.414 C12.106,1.533 13.814,2.153 15.023,3.161 L15.093,3.22 L16.012,2.302 L15.923,2.232 C14.266,0.929 12.348,0.215 10.224,0.108 Z" />
        <path d="M15.027,16.835 C13.686,17.84 11.931,18.478 10.212,18.585 L10.118,18.591 L10.118,19.897 L10.223,19.891 C12.423,19.776 14.393,19.079 15.922,17.879 L16.005,17.814 L15.101,16.78 L15.027,16.835 Z" />
      </g>
    )
  },
  budgetWireless: {
    size: [
      0, 0, 21, 16
    ],
    path: (
      <g>
        <path d="M14,3v1c1.7,0,3,1.3,3,3h1C18,4.8,16.2,3,14,3z" />
        <path d="M14,0v1c3.3,0,6,2.7,6,6h1C21,3.1,17.9,0,14,0z" />
        <path d="M2,8.8V2h5.4C8,1.7,8.7,1.5,9.5,1.5c0.5,0,0.9,0.1,1.4,0.2L12,2V0H0v10.6L0.6,10L2,8.8z" />
        <path d="M10,11.6V15l2,1v-5.1C11.4,11.3,10.7,11.5,10,11.6z" />
        <path d="M9.5,3.5c0.3,0,0.6,0.1,0.9,0.1L7.6,6.4l2,2l2.8-2.8c0.1,0.3,0.1,0.6,0.1,0.9c0,1.7-1.3,3.1-3,3.1c-0.2,0-0.4,0-0.5,0c-0.1,0-0.2,0-0.3,0L4.5,14H2v-2.5l4.4-4.1C6.3,7.1,6.5,6.8,6.5,6.5C6.5,4.8,7.8,3.5,9.5,3.5" />
      </g>
    )
  },
  budgetWired: {
    size: [
      0, 0, 20, 16
    ],
    path: (
      <g>
        <path d="M2,8.8V2h5.4C8,1.7,8.7,1.5,9.5,1.5c0.5,0,0.9,0.1,1.4,0.2L12,2V0H0v10.6L0.6,10L2,8.8z" />
        <path d="M10,11.6V15l2,1v-5.1C11.4,11.3,10.7,11.5,10,11.6z" />
        <path d="M19,0v6h-4.5c0,0.2,0,0.4,0,0.6c0,0.1,0,0.3,0,0.4H20V6V0H19z" />
        <path d="M12.4,5.6L9.6,8.4l-2-2l2.8-2.8c-0.3-0.1-0.6-0.1-0.9-0.1c-1.7,0-3,1.3-3,3c0,0.3-0.2,0.6-0.1,0.9L2,11.5V14h2.5l4.1-4.4c0.3,0.1,0.6,0,0.9,0c1.7,0,3-1.4,3-3.1C12.5,6.3,12.4,5.9,12.4,5.6z" />
      </g>
    )
  },
  mechanicalCylinder: {
    size: [
      0, 0, 20, 16
    ],
    path: (
      <g>
        <path d="M20,4V3h-1.1c-0.1-0.3-0.2-0.6-0.4-0.9l0.6-0.6l-0.7-0.7l-0.6,0.6c-0.3-0.2-0.6-0.3-0.9-0.4V0h-1v1.1c-0.3,0.1-0.6,0.2-0.9,0.4l-0.6-0.6l-0.7,0.7l0.6,0.6c-0.2,0.3-0.3,0.6-0.4,0.9h-0.2l-0.1,0.1L14,4h0.1c0.1,0.3,0.2,0.6,0.4,0.9L14.3,5l0,0c0.1,0.4,0.2,0.7,0.2,1.1l0,0l0.6-0.6c0.3,0.2,0.6,0.3,0.9,0.4V7h1V5.9c0.3-0.1,0.6-0.2,0.9-0.4l0.6,0.6l0.7-0.7l-0.6-0.6c0.2-0.3,0.3-0.6,0.4-0.9H20z M16.5,5C15.7,5,15,4.3,15,3.5S15.7,2,16.5,2S18,2.7,18,3.5S17.3,5,16.5,5z" />
        <path d="M2,8.8V2h5.4C8,1.7,8.7,1.5,9.5,1.5c0.5,0,0.9,0.1,1.4,0.2L12,2V0H0v10.6L0.6,10L2,8.8z" />
        <path d="M10,11.6V15l2,1v-5.1C11.4,11.3,10.7,11.5,10,11.6z" />
        <path d="M12.4,5.6L9.6,8.4l-2-2l2.8-2.8c-0.3-0.1-0.6-0.1-0.9-0.1c-1.7,0-3,1.3-3,3c0,0.3-0.2,0.6-0.1,0.9L2,11.5V14h2.5l4.1-4.4c0.3,0.1,0.6,0,0.9,0c1.7,0,3-1.4,3-3.1C12.5,6.3,12.4,5.9,12.4,5.6z" />
      </g>
    )
  },
  wirelessDigitalCylinder: {
    size: [
      0, 0, 21, 16
    ],
    path: (
      <g>
        <path d="M8.9999998,6.0000001 L8.9999998,10.0000001 C8.9999998,10.5999991 8.5999998,10.9999991 7.9999998,10.9999991 C7.3999998,10.9999991 6.9999998,10.5999991 6.9999998,10.0000001 L6.9999998,8.0000001 L4.9999998,8.0000001 L4.9999998,7.0000001 L6.9999998,7.0000001 L6.9999998,6.0000001 C6.9999998,5.4000001 7.3999998,5 7.9999998,5 C8.5999998,5 8.9999998,5.4000001 8.9999998,6.0000001 Z M1.77635684e-15,15.9999991 L1.9999998,14.9999991 L1.9999998,2 L9.9999998,2 L9.9999998,14.9999991 L11.9999998,15.9999991 L11.9999998,0 L0,0 L0,15.9999991 L1.77635684e-15,15.9999991 Z M12.9999998,8.0000001 L14.9999998,8.0000001 L14.9999998,6.0000001 L12.9999998,6.0000001 L12.9999998,8.0000001 Z M13.9999998,3 L13.9999998,4 C15.6999998,4 16.9999998,5.3 16.9999998,7.0000001 L17.9999998,7.0000001 C17.9999998,4.8 16.1999998,3 13.9999998,3 Z M13.9999998,4.4408921e-16 L13.9999998,1 C17.2999998,1 19.9999998,3.7 19.9999998,7.0000001 L20.9999998,7.0000001 C20.9999998,3.1 17.8999998,4.4408921e-16 13.9999998,4.4408921e-16 Z" />
      </g>
    )
  },
  oneDoorWired: {
    size: [
      0, 0, 20, 17
    ],
    path: (
      <g>
        <path d="M9,6 L9,10.0000001 C9,10.6000001 8.6,11.0000001 8,11.0000001 C7.4,11.0000001 7,10.6000001 7,10.0000001 L7,8.0000001 L5,8.0000001 L5,7 L7,7 L7,6 C7,5.4 7.4,5 8,5 C8.6,5 9,5.4 9,6 Z M19,0 L19,6 L14,6 L14,5 L12,5 L12,0 L0,0 L0,16.0000001 L2,15.0000001 L2,2 L10,2 L10,15.0000001 L12,16.0000001 L12,8.0000001 L14,8.0000001 L14,7 L20,7 L20,6 L20,0 L19,0 Z" />
      </g>
    )
  },
  wirelessGateway: {
    size: [
      0, 0, 11, 16
    ],
    path: (
      <g>
        <path d="M0,11h2v5h1v-5h2V6H0V11z M3,7h1v1H3V7z" />
        <path d="M4,3v1c1.7,0,3,1.3,3,3h1C8,4.8,6.2,3,4,3z" />
        <path d="M4,0v1c3.3,0,6,2.7,6,6h1C11,3.1,7.9,0,4,0z" />
      </g>
    )
  }
};

const remapping = (type) => {
  switch (type) {
    case 'budget-wireless':
      return 'budgetWireless';

    case 'budget-wired':
      return 'budgetWired';

    case 'mechanical-cylinder':
      return 'mechanicalCylinder';

    case 'wireless-digital-cylinder':
    case 'single-door-digital-cylinder':
    case 'single-door-wireless-c-lever':
    case 'single-door-wireless-c-lever-compact':
    case 'single-door-wireless-c-lever-air':
    case 'single-door-wireless-c-lever-pro':
    case 'single-door-wireless-remote-reader':
    case 'single-door-wireless-mechatronics-cylinder':
      return 'wirelessDigitalCylinder';

    case 'one-door-wired':
    case 'motor-lock':
    case 'electric-lock':
    case 'one-door-wired-alarm':
    case 'motor-lock-alarm':
    case 'electric-lock-alarm':
    case 'automatic-door-wired':
    case 'automatic-door-wired-alarm':
    case 'turnstile':
      return 'oneDoorWired';

    case 'wireless-gateway':
      return 'wirelessGateway';

    default:
      return type;
  }
};

export const getSVGSize = (type) => {
  const svg = SVG_ICONS[remapping(type)];
  if (!svg) {
    return 0;
  }
  return svg.size;
};

export default function SVGIcon({ type, fill = 'black', style, withoutContainer, transform, className }) {
  const svg = SVG_ICONS[remapping(type)];
  if (!svg) {
    return null;
  }

  if (withoutContainer) {
    return (
      <g fill={fill} transform={transform} className={className}>
        {svg.path}
      </g>
    );
  }

  const defaultStyle = {
    width: 20,
    height: 20
  };

  return (
    <div style={{ ...defaultStyle, ...style }}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        height="100%"
        viewBox={svg.size.join(' ')}
        fill={fill}
      >
        {svg.path}
      </svg>
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
SVGIcon.propTypes = {
  type: PropTypes.string.isRequired,
  fill: PropTypes.string,
  withoutContainer: PropTypes.bool,
  style: PropTypes.object
};
