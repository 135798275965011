exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._18DRPGUjbiAih0cscKnSEI {\n  display: inline-flex;\n}\n._18DRPGUjbiAih0cscKnSEI > :not(:first-child) ._4-j7AKx5botAU8B97_LsC,\n._18DRPGUjbiAih0cscKnSEI > ._4-j7AKx5botAU8B97_LsC:not(:first-child) {\n  margin-left: -1px;\n}\n._2q8JIBg_JJHpWRPOc_8pG5 ._18DRPGUjbiAih0cscKnSEI > ._4-j7AKx5botAU8B97_LsC {\n  border-left-width: 1px;\n}\n._18DRPGUjbiAih0cscKnSEI._3PTIeu2WxO77OQ4jk0vv8S ._4-j7AKx5botAU8B97_LsC {\n  height: 80px;\n  line-height: 80px;\n  background-color: #fff;\n  border: solid 1px #b5b5b5;\n}\n", ""]);

// exports
exports.locals = {
	"group": "_18DRPGUjbiAih0cscKnSEI",
	"btn": "_4-j7AKx5botAU8B97_LsC",
	"toolbar": "_2q8JIBg_JJHpWRPOc_8pG5",
	"secondary": "_3PTIeu2WxO77OQ4jk0vv8S"
};