exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1KIG32bCA7F9cC6XGfXNZb {\n  width: 100%;\n  display: flex;\n  min-height: 100vh;\n  flex-direction: column;\n  position: relative;\n  background: #ededeb;\n}\n._3jaYc1uYUGUGWEscJyU4K1 {\n  top: 0;\n  width: 100%;\n  height: 80px;\n  z-index: 120;\n  background: #fff;\n  position: fixed;\n}\n._2rEjynAMUfrNcc5P-MzYq7 {\n  display: flex;\n}\n._36uzeqxywyVIl7ao1GjIui {\n  flex: 1;\n  flex-basis: auto;\n  margin-top: 90px;\n}\n@media screen and (min-width: 920px) {\n  ._36uzeqxywyVIl7ao1GjIui {\n    margin-left: 250px;\n  }\n}\n._17y75EL6pe1riR7wNVR0Fa {\n  margin-left: 0px;\n}\n._2NO_9gIVLcOWM8fi4K4bmj {\n  z-index: 1;\n  margin-top: 30px;\n  flex-basis: auto;\n  background: #484848;\n}\n@media screen and (max-width: 919px) {\n  ._1DRPndGNV3NrwzqBsqU8Wh {\n    top: 80px;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    overflow: hidden;\n    background: #000;\n    -webkit-animation: _2teKfbvrZGP7uM6S2laXSx 250ms ease-out;\n    -moz-animation: _2teKfbvrZGP7uM6S2laXSx 250ms ease-out;\n    -o-animation: _2teKfbvrZGP7uM6S2laXSx 250ms ease-out;\n    animation: _2teKfbvrZGP7uM6S2laXSx 250ms ease-out;\n    opacity: 0.5;\n    z-index: 70;\n    position: fixed;\n  }\n}\n@media screen and (min-width: 920px) {\n  ._1KIG32bCA7F9cC6XGfXNZb {\n    margin: 0 auto;\n  }\n  ._3jaYc1uYUGUGWEscJyU4K1 {\n    height: 80px;\n  }\n  ._36uzeqxywyVIl7ao1GjIui {\n    padding: 0;\n  }\n  ._2NO_9gIVLcOWM8fi4K4bmj:not(._17y75EL6pe1riR7wNVR0Fa) {\n    margin-left: 250px;\n  }\n}\n@media only screen and (min-width: 48em) {\n  ._2TnLMmFEGb8cePY0KNWWtp {\n    margin-right: 5px;\n  }\n  ._198yQI-k5tw635PYoSMtYz {\n    margin-left: 5px;\n  }\n}\n@media screen and (max-width: 921px) {\n  ._2TnLMmFEGb8cePY0KNWWtp {\n    margin-bottom: 5px;\n  }\n  ._198yQI-k5tw635PYoSMtYz {\n    margin-top: 5px;\n  }\n}\n@media print {\n  ._3jaYc1uYUGUGWEscJyU4K1 {\n    position: relative;\n  }\n  ._36uzeqxywyVIl7ao1GjIui {\n    margin-top: 10px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"page": "_1KIG32bCA7F9cC6XGfXNZb",
	"header": "_3jaYc1uYUGUGWEscJyU4K1",
	"contentWrapper": "_2rEjynAMUfrNcc5P-MzYq7",
	"content": "_36uzeqxywyVIl7ao1GjIui",
	"fullWidth": "_17y75EL6pe1riR7wNVR0Fa",
	"footer": "_2NO_9gIVLcOWM8fi4K4bmj",
	"backdrop": "_1DRPndGNV3NrwzqBsqU8Wh",
	"fadeInFromNone": "_2teKfbvrZGP7uM6S2laXSx",
	"left": "_2TnLMmFEGb8cePY0KNWWtp",
	"right": "_198yQI-k5tw635PYoSMtYz"
};