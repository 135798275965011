import React from 'react';
import ContentLoader from 'react-content-loader';
import { DORMAKABA_BLUE } from '../../constants/colours';

const ThreeDots = () => (
  <div style={{ textAlign: 'center' }}>
    <ContentLoader foregroundColor={DORMAKABA_BLUE}
      viewBox="0 0 400 160"
      height={160}
      width={400}
      speed={2}
      backgroundColor="transparent"
    >      
      <circle cx="160" cy="80" r="5" />
      <circle cx="180" cy="80" r="5" />
      <circle cx="200" cy="80" r="5" />
    </ContentLoader>
  </div>
);

export default ThreeDots;
