import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './SideMenu.styl';

const SideMenu = {};

SideMenu.Container = ({ status, children }) => {
  const classes = classNames({
    [styles.container]: true,
    [styles.active]: status
  });

  return (
    <aside id="navigation" className={classes}
     style={{height: '100%','backgroundColor': '#003594'}}>
      <nav>
        {children}
      </nav>
    </aside>
  );
};

SideMenu.Container.propTypes = {
  children: PropTypes.node,
  status: PropTypes.bool
};


SideMenu.Group = ({ level, isActive, children }) => {
  const classes = classNames({
    [styles.group]: true,
    [styles[`level${level}`]]: true
  });

  return (
    <ul className={classes}>
    
      {children}
    </ul>
  );
};

SideMenu.Group.propTypes = {
  level: PropTypes.number,
  isActive: PropTypes.bool,
  children: PropTypes.node
};


SideMenu.Item = ({ level, isActive, children }) => {
  const classes = classNames({
    [styles.item]: true,
    [styles[`item${level}`]]: true,
    [styles.itemActive]: isActive
  });

  return (
    <li className={classes}>
      {children}
    </li>
  );
};

SideMenu.Item.propTypes = {
  level: PropTypes.number,
  isActive: PropTypes.bool,
  children: PropTypes.node
};

export default SideMenu;
