import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Login } from './redux/action';
import DefaultLayout from './Pages/DefaultLayout/index';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { ForgotPassword, RequireNewPassword, SignIn, withAuthenticator, Greetings, VerifyContact } from 'aws-amplify-react';
import customTheme from './AWSAmplifyCustomTheme';

Auth.configure({
  Auth: {
    mandatorySignIn: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_U24uJfY6N',
    userPoolWebClientId: '1dioq4qplnhsbaitrrdjlm3id1'
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    API.configure({
      API: {
        endpoints: [
          {
            name: 'peoplecount',
            endpoint: 'https://vwyvuu1b1k.execute-api.us-west-2.amazonaws.com/test',
            region: 'us-west-2',
            custom_header: async () => {
              return { 'api-user-auth': this.props.token }
            }
          },
          {
            name: 'Asset',
            endpoint: 'https://1yxo0tjqu3.execute-api.us-west-2.amazonaws.com/test',
            region: 'us-west-2',
            custom_header: async () => {
              return { 'api-user-auth': this.props.token }
            }
          },
          {
            name: 'CAInfra',
            endpoint: 'https://js17iisz2l.execute-api.us-west-2.amazonaws.com/test',
            region: 'us-west-2',
            custom_header: async () => {
              return { 'api-user-auth': this.props.token }
            }
          },
          {
            name: 'entrivo-util-api',
            endpoint: 'https://7rpirzgk30.execute-api.ap-southeast-1.amazonaws.com/latest',
            region: 'ap-southeast-1',
            custom_header: async () => {
              return { Authorization: `Bearer ${this.props.token}` }
            }
          }
        ]
      }
    });
  }

  componentDidMount() {
    Auth.currentSession().then(data => {
      this.props.Login(data);
    });
  }

  render() {
    return (
      <HashRouter>
        <DefaultLayout />
      </HashRouter>);
  }
}

const mapStateToProps = state => {
  return { token: state.token };
};

const mapDispatchToProps = dispatch => {
  return { Login: data => dispatch(Login(data)) };
};

export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(App), true, [<SignIn key='0' />, <ForgotPassword key='1' />, <RequireNewPassword key='2' />, <Greetings key='3' />, <VerifyContact key='4' />], null, customTheme);
