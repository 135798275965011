import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../base';
import styles from './Icon.styl';

export default function Icon({ name, textSized, small, large, xlarge, error, warning, highlight, info, success, white, gray, style, onClick, className, ...props }) {

  const classes = classNames({
    [name]: true,
    [styles.icon]: true,
    [styles.textSized]: textSized,
    [styles.small]: small,
    [styles.large]: large,
    [styles.xlarge]: xlarge,
    [styles.error]: error,
    [styles.warning]: warning,
    [styles.info]: info || highlight,
    [styles.success]: success,
    [styles.white]: white,
    [styles.gray]: gray,
    [styles.clickable]: onClick,
    [className]: className
  });


  return <i className={classes} style={style} onClick={onClick} />;
}

Icon.propTypes = {
  name: PropTypes.string,
  textSized: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  xlarge: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  highlight: PropTypes.bool,
  info: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  gray: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func
};
