exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".XzQmiDHGhe2B-5wsDZBk8 {\n  position: relative;\n  overflow: hidden;\n  transition: height 0.1s ease-in;\n}\n.XzQmiDHGhe2B-5wsDZBk8._2-kznNjO5MQYFbY7qLYqYk {\n  position: relative;\n}\n", ""]);

// exports
exports.locals = {
	"collapsible": "XzQmiDHGhe2B-5wsDZBk8",
	"open": "_2-kznNjO5MQYFbY7qLYqYk"
};