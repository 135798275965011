exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".kGDig5Nr5QM39JynTX6Xw {\n  display: inline-block;\n  transition: transform 0.1s;\n}\n.kGDig5Nr5QM39JynTX6Xw._3s9KZ130sMZ0xDUaT9hZ1s {\n  transform: rotate(180deg);\n}\n", ""]);

// exports
exports.locals = {
	"icon": "kGDig5Nr5QM39JynTX6Xw",
	"open": "_3s9KZ130sMZ0xDUaT9hZ1s"
};