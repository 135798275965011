import React from 'react';
import classNames from 'classnames';

import Icon from './Icon';
import styles from './Collapsible.Icon.styl';

export default function CollapsibleIcon({ open, className, ...rest }) {
  const classes = classNames({
    [styles.icon]: true,
    [className]: className,
    [styles.open]: open
  });
  return (
    <Icon name="doka-icon-openinline" className={classes} {...rest} />
  );
}
