export default {
    button: {
        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--dormakaba-blue')
    },
    navBar: {
        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--dormakaba-white'),
        position: 'fixed',
        top: '0',
        width: '100%',
        zIndex: 1,
        height: '80px',        
    },
    navRight: {
        padding: '10px'
    },
    navButton: {
        backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--dormakaba-red')
    }
};