exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2sRw9oiFOydralzf0QNt3v {\n  display: block;\n  padding: 12px 20px 12px 20px;\n  border-top: solid 1px #b5b5b5;\n  color: #fff;\n  text-align: right;\n  font-family: 'Poppins', 'FreeSans', 'Arimo', 'Droid Sans', 'Helvetica', 'Arial', sans-serif;\n  font-size: 16px;\n}\n", ""]);

// exports
exports.locals = {
	"footer": "_2sRw9oiFOydralzf0QNt3v"
};