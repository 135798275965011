import React from 'react';
import classNames from 'classnames';

import SVGIcon from './SVGIcon';
import styles from './Spinner.styl';

export default function Spinner({ className }) {
  const classes = classNames({
    [className]: className,
    [styles.spinner]: true
  });

  return (
    <div className={classes}>
      <SVGIcon type="spinner" fill="#484848" style={{ display: 'flex' }} />
    </div>
  );
}
