exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2hC4POI_xX_peiGZWQelmx {\n  font-size: 30px;\n}\n._2EJzVM2NE-pod3Tfm8DHXz {\n  font-size: 1em;\n}\n._5n8GjpU0ujm2hOq35zRJU {\n  font-size: 26px;\n}\n._2myp1zndPPoYJ6ywDNhQEJ {\n  font-size: 40px;\n}\n.SmokdSlsWRh6BVmfRHro {\n  font-size: 60px;\n}\n._2CnWKg9PBtpYBr8N9fwMg4 {\n  color: #e4002b;\n}\n._3I937z0d5ecJGXYou2e0wP {\n  color: #5fa82a;\n}\n._19QNDS7XdXZDC42_KvmAwH {\n  color: #ffb726;\n}\n._3zDCxAkTsqjqZDmz7Yirqh {\n  color: #003594;\n}\n._3ropBTqQV3S4eS4fP5KB9x {\n  color: #fff;\n}\n._5asDwijHRvjYmFzWKtfhD {\n  color: #484848;\n}\n._39CZz4a7KUsmAZ3_Ou1Gmw {\n  cursor: pointer;\n}\n._39CZz4a7KUsmAZ3_Ou1Gmw:hover {\n  opacity: 0.6;\n}\n", ""]);

// exports
exports.locals = {
	"icon": "_2hC4POI_xX_peiGZWQelmx",
	"textSized": "_2EJzVM2NE-pod3Tfm8DHXz",
	"small": "_5n8GjpU0ujm2hOq35zRJU",
	"large": "_2myp1zndPPoYJ6ywDNhQEJ",
	"xlarge": "SmokdSlsWRh6BVmfRHro",
	"error": "_2CnWKg9PBtpYBr8N9fwMg4",
	"success": "_3I937z0d5ecJGXYou2e0wP",
	"warning": "_19QNDS7XdXZDC42_KvmAwH",
	"info": "_3zDCxAkTsqjqZDmz7Yirqh",
	"white": "_3ropBTqQV3S4eS4fP5KB9x",
	"gray": "_5asDwijHRvjYmFzWKtfhD",
	"clickable": "_39CZz4a7KUsmAZ3_Ou1Gmw"
};